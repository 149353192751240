import React from "react"
import Layout from "../components/_App/Layout"
import Seo from "../components/_App/Seo"
import NavbarTwo from "../components/_App/NavbarTwo"

// Images 
import errorImg from '../assets/images/error.png'
import { Link } from "gatsby"
import Footer from "../components/_App/Footer"
import Copyright from "../components/_App/Copyright"

const NotFoundPage = () => (
      <Layout>
          <Seo title="404: Not found" />

          <NavbarTwo />

          <section className="error ptb-100">
            <div className="container">
                <div className="error-content">
                      <img 
                          src={errorImg} 
                          alt="Error"
                      />
                      <h4>Sorry We Can`t Find That Page!</h4>
                      <p>The page you are looking for was moved, removed, renamed or never existed.</p>
                      <div className="input-group mb-3">
                          <input 
                            type="text" 
                            className="form-control"
                            placeholder="Your Email"
                          />
                          <button className="btn " type="button" aria-label="submit"><i className="fas fa-search"></i></button>
                      </div>
                      <Link className="default-button" to="/">Take Me Home <i className="fas fa-arrow-right"></i></Link>
                </div>
            </div>
          </section>

          <Footer />

          <Copyright />
      </Layout>
)

export default NotFoundPage
